import { PostHogConfig } from 'posthog-js';

const PERSON_PROFILES = 'identified_only'; // or 'always' to create profiles for anonymous users as well

const DEFAULTS = {
  api_host: '/api/posthog-cdn',
  person_profiles: PERSON_PROFILES as 'always' | 'identified_only',
  disable_session_recording: true,
};

interface EnvironmentConfig extends Partial<PostHogConfig> {
  // Include API key for ease of configuration
  apiKey: string;
}

type PostHogEnvironments = {
  [env: string]: EnvironmentConfig;
};

export const postHogConfigs: PostHogEnvironments = {
  local: {
    ...DEFAULTS,
    apiKey: '',
  },
  stable: {
    ...DEFAULTS,
    apiKey: 'phc_ddubnGsvPFsJDWMD1jNrPHAVHNC6qGEkCojHl7RTGZ6',
  },
  demo: {
    ...DEFAULTS,
    apiKey: '',
  },
  devel: {
    ...DEFAULTS,
    apiKey: '',
  },
  UAT: {
    ...DEFAULTS,
    apiKey: '',
  },
  production: {
    ...DEFAULTS,
    apiKey: 'phc_ddubnGsvPFsJDWMD1jNrPHAVHNC6qGEkCojHl7RTGZ6',
  },
};
