import { useCallback, useContext, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import ApplicationContext from 'components/Application/Context';
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import benLabsLogoWhite from 'components/assets/img/ben_labs_logo_white.png';
/* eslint-enable @typescript-eslint/ban-ts-comment */
import AppHeaderMenu from './Menu/Menu';
import AppHeaderHelpMenu from './HelpMenu/HelpMenu';
import './AppHeader.scss';
import Banner from './Banner/Banner';

export const isProd = () => {
  const { hostname } = window.location;

  return hostname === 'app.benlabs.com' || hostname === 'stable.goben.rocks';
};

export default function AppHeader() {
  const { token, permissions, user, logout } = useContext(ApplicationContext);
  const navigate = useNavigate();
  const [isExpandedMobileMenu, setIsExpandedMobileMenu] = useState(false);
  const toggleMobileMenu = () => setIsExpandedMobileMenu(!isExpandedMobileMenu);

  const isAuthenticated = Boolean(token);

  const onLogout = useCallback(() => {
    logout();
    navigate('/user/login');
  }, [logout, navigate]);

  const hasProductPlacementTeam = user?.team?.includes('product placement');

  return (
    <header className="app-header print-hide">
      <div className="app-header__inner">
        <nav className="app-header__navigation">
          <div className="app-header__logo__wrapper">
            <Link to="/campaigns/list" className="app-header__logo">
              <img src={benLabsLogoWhite} alt="BEN Logo" />
              {hasProductPlacementTeam ? (
                <span className="app-header-traditional">
                  PRODUCT PLACEMENT
                </span>
              ) : null}
            </Link>
            <button
              type="button"
              className="app-header__mobile-menu__toggle"
              onClick={toggleMobileMenu}
            >
              <span
                className={classNames('app-header__mobile-menu__icon', {
                  'is-open': isExpandedMobileMenu,
                })}
              />
            </button>
          </div>

          <div
            className={classNames('app-header__navigation__inner', {
              'is-open': isExpandedMobileMenu,
            })}
          >
            {token && permissions && user && (
              <>
                <AppHeaderMenu permissions={permissions} />
                <AppHeaderHelpMenu user={user} onLogout={onLogout} />
              </>
            )}
            {!isAuthenticated && (
              <ul className="app-header__secondary-menu">
                <li className="app-header__menu-item">
                  <NavLink
                    to="/user/login"
                    className={({ isActive }: { isActive: boolean }) => {
                      if (isActive) {
                        return 'app-header__menu-link is-active';
                      }
                      return 'app-header__menu-link';
                    }}
                  >
                    Sign in
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </div>
      {!isProd() && hasProductPlacementTeam ? <Banner /> : null}
    </header>
  );
}
