import React from 'react';
import { children as childrenType } from 'components/utils/customPropTypes';
import './Group.scss';

const cbn = 'production-audience-group';

function ProductionAudienceGroup({ children, dataCy }) {
  return (
    <section className={cbn} data-cy={dataCy}>
      {children}
    </section>
  );
}

ProductionAudienceGroup.propTypes = {
  children: childrenType.isRequired,
};

export default ProductionAudienceGroup;
