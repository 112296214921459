import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import gql from 'graphql-tag';
import { getToken } from 'store/authentication/session';

const watchApiClient = new ApolloClient({
  link: createHttpLink({
    uri: '/watch-api/graphql',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only', // Disable caching by default
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

export const GET_VIEWERSHIPS = gql`
  query GetViewershipsBySource($sourceId: Int!) {
    viewerships(sourceId: $sourceId) {
      id
      type
      notes
      premiereDate
      provider
      premiereImpressions
      past30Impressions
      past60Impressions
      past90Impressions
      past365Impressions
      station {
        id
        name
        country
      }
      premiereDetails {
        male
        female
        age_1_17
        age_18_24
        age_25_34
        age_35_44
        age_45_54
        age_55_65
        age_65_plus
        age_21_plus
        ethnicity_white
        ethnicity_hispanic
        ethnicity_black
        ethnicity_asian
        ethnicity_other
        income_under_20
        income_under_50
        income_20_49
        income_50_74
        income_75_99
        income_100_149
        income_150_199
        income_100_plus
        income_200_plus
        extraData
        description
      }
      past30Details {
        male
        female
        age_1_17
        age_18_24
        age_25_34
        age_35_44
        age_45_54
        age_55_65
        age_65_plus
        age_21_plus
        ethnicity_white
        ethnicity_hispanic
        ethnicity_black
        ethnicity_asian
        ethnicity_other
        income_under_20
        income_under_50
        income_20_49
        income_50_74
        income_75_99
        income_100_149
        income_150_199
        income_100_plus
        income_200_plus
        extraData
        description
      }
      past60Details {
        male
        female
        age_1_17
        age_18_24
        age_25_34
        age_35_44
        age_45_54
        age_55_65
        age_65_plus
        age_21_plus
        ethnicity_white
        ethnicity_hispanic
        ethnicity_black
        ethnicity_asian
        ethnicity_other
        income_under_20
        income_under_50
        income_20_49
        income_50_74
        income_75_99
        income_100_149
        income_150_199
        income_100_plus
        income_200_plus
        extraData
        description
      }
      past90Details {
        male
        female
        age_1_17
        age_18_24
        age_25_34
        age_35_44
        age_45_54
        age_55_65
        age_65_plus
        age_21_plus
        ethnicity_white
        ethnicity_hispanic
        ethnicity_black
        ethnicity_asian
        ethnicity_other
        income_under_20
        income_under_50
        income_20_49
        income_50_74
        income_75_99
        income_100_149
        income_150_199
        income_100_plus
        income_200_plus
        extraData
        description
      }
      past365Details {
        male
        female
        age_1_17
        age_18_24
        age_25_34
        age_35_44
        age_45_54
        age_55_65
        age_65_plus
        age_21_plus
        ethnicity_white
        ethnicity_hispanic
        ethnicity_black
        ethnicity_asian
        ethnicity_other
        income_under_20
        income_under_50
        income_20_49
        income_50_74
        income_75_99
        income_100_149
        income_150_199
        income_100_plus
        income_200_plus
        extraData
        description
      }
    }
  }
`;

export type ExtraDataType = {
  [index: string]: string;
};

export type ViewershipDetail = {
  male: number;
  female: number;
  age_1_17: number;
  age_18_24: number;
  age_25_34: number;
  age_35_44: number;
  age_45_54: number;
  age_55_65: number;
  age_65_plus: number;
  age_21_plus: number;
  ethnicity_white: number;
  ethnicity_hispanic: number;
  ethnicity_black: number;
  ethnicity_asian: number;
  ethnicity_other: number;
  income_under_20: number;
  income_under_50: number;
  income_20_49: number;
  income_50_74: number;
  income_75_99: number;
  income_100_149: number;
  income_150_199: number;
  income_100_plus: number;
  income_200_plus: number;
  extraData: ExtraDataType;
  description: string;
};

export type Viewership = {
  id: string;
  type: string;
  notes: string;
  premiereDate: string;
  provider: string;
  premiereImpressions: number;
  past30Impressions: number;
  past60Impressions: number;
  past90Impressions: number;
  past365Impressions: number;
  station: {
    id: number;
    name: string;
    country: string;
  };
  premiereDetails: ViewershipDetail;
  past30Details: ViewershipDetail;
  past60Details: ViewershipDetail;
  past90Details: ViewershipDetail;
  past365Details: ViewershipDetail;
};

export type ViewershipResponse = {
  viewerships: Viewership[];
};
export { watchApiClient };
