export type ValueType =
  | 'string'
  | 'wholeNumber'
  | 'floatNumber'
  | 'percentage'
  | 'element'
  | 'link';

export function formatPercentage(value: number) {
  if (typeof value !== 'number' || Number.isNaN(value)) {
    return '--';
  }

  const formattedValue = Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return formattedValue ?? '--';
}

export function formatWholeNumber(value: number) {
  if (!value) {
    return '--';
  }
  const formattedValue = Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 0,
  }).format(value);

  return formattedValue ?? '--';
}

export function formatFloatNumber(value: number) {
  if (!value) {
    return '--';
  }
  const formattedValue = Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: value > 1000 ? 2 : 0,
    maximumFractionDigits: 2,
  }).format(value);

  return formattedValue ?? '--';
}

export function formatDate(value: string) {
  if (!value) {
    return '';
  }

  const date = new Date(value);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}

export function formatValue(value: string | number | null, type: ValueType) {
  switch (type) {
    case 'floatNumber':
      return formatFloatNumber(value as number);

    case 'wholeNumber':
      return formatWholeNumber(value as number);

    case 'percentage':
      return formatPercentage(value as number);

    case 'link':
      return value;

    default:
      return value ?? '--';
  }
}
