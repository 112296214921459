import React from 'react';
import { string, number } from 'prop-types';
import UIProgressBar from 'components/UI/ProgressBar/ProgressBar';
import UINumber from 'components/UI/Number/Number';

import './Row.scss';

const cbn = 'production-audience-row';

function ProductionAudienceRow({ dataCy, label, value }) {
  return (
    <section data-cy={dataCy} className={cbn}>
      <span className={`${cbn}__label`}>{label}</span>
      <span className={`${cbn}__value`}>
        <UINumber suffix="%" format="0">
          {value}
        </UINumber>
      </span>
      <UIProgressBar className={`${cbn}__progress__bar`} value={value} />
    </section>
  );
}

ProductionAudienceRow.propTypes = {
  dataCy: string,
  label: string.isRequired,
  value: number,
};

ProductionAudienceRow.defaultProps = {
  value: 0,
};

export default ProductionAudienceRow;
