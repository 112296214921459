import React from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GridReadyEvent,
  GridApi,
  SortChangedEvent,
  CellClickedEvent,
} from 'ag-grid-community';

export default function AgGrid({
  data,
  columnDefs,
  className = null,
  striped = false,
  title = null,
  theme = 'alpine',
  pinnedBottomRowData,
  pinnedTopRowData,
  onSortChanged,
  enableCellTextSelection = false,
  suppressCellFocus = true,
  rowSelection,
  enableRangeSelection = false,
  onCellClicked,
  onGridReady,
}: {
  data: { [index: string]: unknown }[];
  columnDefs: ColDef[];
  className?: string | null;
  striped?: boolean;
  title?: string | null;
  theme?: 'alpine' | 'destination';
  pinnedBottomRowData?: { [key: string]: unknown }[];
  pinnedTopRowData?: { [key: string]: unknown }[];
  onSortChanged?: (event: SortChangedEvent) => void;
  enableCellTextSelection?: boolean;
  suppressCellFocus?: boolean;
  rowSelection?: 'single' | 'multiple';
  enableRangeSelection?: boolean;
  onCellClicked?: (params: CellClickedEvent) => void;
  onGridReady?: (params: GridReadyEvent) => void;
}) {
  const gridApiRef = React.useRef<GridApi | null>(null);
  const onGridReadyLocal = React.useCallback(
    (event: GridReadyEvent) => {
      const {api} = event;

      if (!api) {
        return;
      }

      api.sizeColumnsToFit();
      gridApiRef.current = api;

      if (onGridReady) {
        onGridReady(event);
      }
    },
    [onGridReady],
  );

  React.useEffect(() => {
    const handleResize = () => {
      gridApiRef.current?.sizeColumnsToFit();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!columnDefs?.length || !data?.length) {
    return null;
  }

  return (
    <div
      className={`ag-theme-${theme} ${className ?? ''} ${
        striped === true ? 'striped' : ''
      }`}
      style={{ width: '100%' }}
    >
      {title ? <h2>{title}</h2> : null}
      <AgGridReact
        rowData={data}
        columnDefs={columnDefs}
        loadingOverlayComponent="customLoadingOverlay"
        domLayout="autoHeight"
        enableCharts
        enableRangeSelection={enableRangeSelection}
        onGridReady={onGridReadyLocal}
        onSortChanged={onSortChanged}
        rowHeight={48}
        headerHeight={40}
        pinnedBottomRowData={pinnedBottomRowData}
        pinnedTopRowData={pinnedTopRowData}
        enableCellTextSelection={enableCellTextSelection}
        suppressCellFocus={suppressCellFocus}
        rowSelection={rowSelection}
        defaultColDef={{
          cellStyle: () => ({
            display: 'flex',
            alignItems: 'center',
          }),
          sortable: true,
          enableCellChangeFlash: true,
          resizable: true,
          suppressMenu: true,
        }}
        onCellClicked={onCellClicked}
      />
    </div>
  );
}
