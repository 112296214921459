export const COUNTRIES = [
  {
    label: 'Australia',
    value: 'au',
  },
  {
    label: 'Austria',
    value: 'at',
  },
  {
    label: 'Belgium',
    value: 'be',
  },
  {
    label: 'Canada',
    value: 'ca',
  },
  {
    label: 'Denmark',
    value: 'dk',
  },
  {
    label: 'Finland',
    value: 'fi',
  },
  {
    label: 'France',
    value: 'fr',
  },
  {
    label: 'Germany',
    value: 'de',
  },
  {
    label: 'Hong Kong',
    value: 'hk',
  },
  {
    label: 'India',
    value: 'in',
  },
  {
    label: 'Indonesia',
    value: 'id',
  },
  {
    label: 'Ireland',
    value: 'ie',
  },
  {
    label: 'Italy',
    value: 'it',
  },
  {
    label: 'Japan',
    value: 'jp',
  },
  {
    label: 'Malaysia',
    value: 'my',
  },
  {
    label: 'Mexico',
    value: 'mx',
  },
  {
    label: 'Netherlands',
    value: 'nl',
  },
  {
    label: 'New Zealand',
    value: 'nz',
  },
  {
    label: 'Norway',
    value: 'no',
  },
  {
    label: 'Philippines',
    value: 'ph',
  },
  {
    label: 'Poland',
    value: 'pl',
  },
  {
    label: 'Singapore',
    value: 'sg',
  },
  {
    label: 'South Africa',
    value: 'zaf',
  },
  {
    label: 'South Korea',
    value: 'kr',
  },
  {
    label: 'Spain',
    value: 'es',
  },
  {
    label: 'Sweden',
    value: 'se',
  },
  {
    label: 'Switzerland',
    value: 'ch',
  },
  {
    label: 'Taiwan',
    value: 'tw',
  },
  {
    label: 'Thailand',
    value: 'th',
  },
  {
    label: 'United Kingdom',
    value: 'uk',
  },
  {
    label: 'United States',
    value: 'us',
  },
  {
    label: 'Vietnam',
    value: 'vn',
  },
];
// We need to add GB as YT uses this country code.
export const ROLLUP_COUNTRIES = COUNTRIES.map(
  (country) => country.value,
).concat(['gb']);
export const ROLLUP_COUNTRIES_WITH_GLOBAL = ROLLUP_COUNTRIES.concat(['global']);

export const COUNTRIES_BY_CODE = {};
COUNTRIES.forEach((country) => {
  COUNTRIES_BY_CODE[country.value] = country.label;
});

const videoNull = { mediaType: 'video', style: null, label: 'Video' };
const streamNull = { mediaType: 'stream', style: null, label: 'Stream' };
const postNull = { mediaType: 'post', style: null, label: 'Post' };

export const MEDIA_TYPES_BY_PLATFORM = [
  {
    label: 'YouTube',
    id: 'youtube',
    media: [
      { mediaType: 'video', style: 'integration', label: 'Integration' },
      { mediaType: 'video', style: 'thematic', label: 'Thematic' },
      { mediaType: 'video', style: 'dedicated', label: 'Dedicated' },
      streamNull,
    ],
  },
  {
    label: 'Instagram',
    id: 'instagram',
    media: [
      videoNull,
      { mediaType: 'story', style: null, label: 'Story' },
      { mediaType: 'photo', style: null, label: 'Photo' },
    ],
  },
  {
    label: 'Twitter',
    id: 'twitter',
    media: [videoNull, postNull],
  },
  {
    label: 'Facebook',
    id: 'facebook',
    media: [videoNull, postNull],
  },
  {
    label: 'Twitch',
    id: 'twitch',
    media: [streamNull],
  },
  {
    label: 'TikTok',
    id: 'tiktok',
    media: [videoNull],
  },
];

export const OPTIMIZER_PLATFORMS_OPTIONS = [
  { value: 'youtube', label: 'YouTube' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'twitter', label: 'Twitter' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'twitch', label: 'Twitch' },
  { value: 'tiktok', label: 'Tik Tok' },
  { value: 'other', label: 'Other' },
];

export const PRODUCTION_OPTIMIZER_PLATFORMS_LIST =
  OPTIMIZER_PLATFORMS_OPTIONS.map(({ value }) => value);

export const metricTypeByMediaType = {
  photo: 'contentPieces',
  post: 'contentPieces',
  story: 'views',
  stream: 'ccv',
  video: 'views',
  reels: 'views',
  live: 'views',
};

export const DELIVERABLES_EXECUTION_STATUS = {
  forReview: {
    phase: 'list',
    label: 'For Review',
  },
  listApproved: {
    phase: 'list',
    label: 'List Approved',
  },
  listRejected: {
    phase: 'list',
    label: 'List Rejected',
  },
  activeTarget: {
    phase: 'planning',
    label: 'Active Target',
    influencer: true,
    influencerLabel: 'Active Target',
    awarenessScenario: true,
  },
  reachedOut: {
    phase: 'planning',
    label: 'Reached Out',
    influencerLabel: 'Emailed',
    influencer: true,
    awarenessScenario: true,
  },
  responded: {
    phase: 'planning',
    label: 'Responded',
  },
  sentProduct: {
    phase: 'pending',
    label: 'Sent Product',
  },
  offerExtended: {
    phase: 'pending',
    label: 'Offer Extended',
    influencer: true,
  },
  inNegotiation: {
    phase: 'pending',
    label: 'In Negotiation',
    influencerLabel: 'Negotiation',
    awarenessScenario: true,
    summaryStats: true,
    summaryStatsOrder: 3,
    influencer: true,
  },
  offerAccepted: {
    phase: 'pending',
    label: 'Offer Accepted',
    influencerLabel: 'Committed',
    awarenessScenario: true,
    summaryStats: true,
    summaryStatsOrder: 2,
    influencer: true,
  },
  ndaSent: {
    phase: 'pending',
    label: 'NDA Sent',
    influencerLabel: 'NDA Sent',
    awarenessScenario: true,
    influencer: true,
  },
  delayed: {
    phase: 'pending',
    label: 'Delayed',
    influencer: true,
    influencerLabel: 'Delayed',
    awarenessScenario: true,
    summaryStats: true,
    summaryStatsOrder: 1,
  },
  notApplicable: {
    phase: 'confirmed',
    label: 'Not Applicable',
  },
  contractOut: {
    phase: 'confirmed',
    label: 'Contract Out',
    influencerLabel: 'Contract Sent',
    influencer: true,
    awarenessScenario: true,
  },
  contractSigned: {
    phase: 'confirmed',
    label: 'Contract Signed',
    influencerLabel: 'Contract Signed',
    awarenessScenario: true,
    influencer: true,
  },
  contentDraft: {
    phase: 'confirmed',
    label: 'Content Draft',
  },
  approved: {
    phase: 'confirmed',
    label: 'Approved',
  },
  sceneShot: {
    phase: 'confirmed',
    label: 'Scene Shot',
  },
  receivedPOP: {
    phase: 'confirmed',
    label: 'Received POP',
  },
  liveReleased: {
    phase: 'completed',
    label: 'Live/Released',
    influencerLabel: 'Live',
    influencer: true,
    awarenessScenario: true,
    summaryStats: true,
    summaryStatsOrder: 0,
  },
  sentForPayment: {
    phase: 'completed',
    label: 'Sent For Payment',
    influencerLabel: 'Not Paid',
    influencer: true,
    awarenessScenario: true,
    summaryStats: true,
    summaryStatsOrder: 4,
  },
  incomplete: {
    phase: 'completed',
    label: 'Incomplete',
  },
  noExposure: {
    phase: 'rejected',
    label: 'Propped - No Exposure',
  },
  noResponse: {
    phase: 'rejected',
    label: 'No Response',
    influencerLabel: 'Closed: No Response',
    influencer: true,
  },
  timing: {
    phase: 'rejected',
    label: 'Timing',
    influencerLabel: 'Closed: Timing',
    influencer: true,
  },
  budget: {
    phase: 'rejected',
    label: 'Budget',
    influencerLabel: 'Closed: Budget',
    influencer: true,
  },
  notInterested: {
    phase: 'rejected',
    label: 'Not Interested',
    influencerLabel: 'Closed: Not Interested',
    influencer: true,
  },
  benRejected: {
    phase: 'rejected',
    label: 'BEN Rejected',
    influencerLabel: 'Closed: BEN Rejected',
    influencer: true,
  },
  clientRejected: {
    phase: 'rejected',
    label: 'Client Rejected',
  },
  productionRejected: {
    phase: 'rejected',
    label: 'Production Rejected',
  },
};

export const TRACKED_LINKS = 'tracked links';
export const PROMO_CODES = 'promo codes';
export const POST_PURCHASE_SURVEYS = 'post purchase surveys';
export const METRIC_ORIGIN_TYPES = [
  TRACKED_LINKS,
  PROMO_CODES,
  POST_PURCHASE_SURVEYS,
];
